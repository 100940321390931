import Staff from "@/request/routes/Staff";
import {
  TagItemFactory,
  TagItem,
} from "@/components/plataform/commonTags/class/TagItem";

const state = {
  items: [],
  parentType: null,
  parentId: null,
  loading: false,
  tagSelected: null,
};

const getters = {
  all: (state) => state.items,
  loading: (state) => state.loading,
  tagSelected: (state) => state.tagSelected,
};

const mutations = {
  SET_ITEMS(state, payload = []) {
    state.items = payload.map((item) => TagItemFactory.make(item));
  },
  SET_PARENT(state, payload = { parentType, parentId }) {
    state.parentId = payload.parentId;
    state.parentType = payload.parentType;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_TAG_SELECTED(state, tag) {
    state.tagSelected = tag;
  },
  REMOVE_TAG(state, id) {
    const index = state.items.findIndex((h) => h.id === id);
    index > -1 && state.items.splice(index, 1);
  },
};

const actions = {
  async load({ commit, dispatch }, payload) {
    const { parentId = null, parentType = null } = payload;
    commit("SET_PARENT", { parentId, parentType });
    await dispatch("search", { reset: true });
  },
  async search(
    { commit, state, rootGetters },
    payload = { search: "", reset: false }
  ) {
    if (rootGetters["userData/isIntegrator"]) return;
    try {
      const { search = "", reset = false } = payload;
      commit("SET_LOADING", true);
      const result = await Staff.listTagGroups(state.parentType, search);
      if (!result.data) {
        throw result.error;
      }
      const items =
        result.data?.items?.map((item) => TagItemFactory.make(item)) || [];
      if (reset) {
        commit("SET_ITEMS", items);
      }
      return items;
    } catch (e) {
      throw e;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  /**
   * @param {*} param0
   * @param {TagItem} tag
   */
  removeGroup({ commit }, tag) {
    commit("SET_TAG_SELECTED", tag);
    commit("REMOVE_TAG", tag.id);
    setTimeout(() => commit("SET_TAG_SELECTED", null), 100);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
