import Icons from "@/constants/Icons";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      icon: Icons.VIEWDASHBOARD,
      title: "Visão Geral",
      local: ["navBar"],
      permissions: ["admin", "staff"],
    },
    component: () => import("@/views/dashboard/PageDashboard.vue"),
  },
  {
    path: "/dashboard-bi",
    name: "DashboardBI",
    meta: {
      icon: Icons.CHARTBOXOUTLINE,
      title: "Dashboard BI",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["dashboardBI"],
    },
    component: () => import("@/views/dashboard/PageDashboardBI.vue"),
  },
  {
    path: "/dashboardIntegrator",
    name: "DashboardIntegrator",
    meta: {
      icon: Icons.VIEWDASHBOARD,
      title: "Visão Geral",
      local: ["navBar"],
      permissions: ["integrator"],
    },
    component: () => import("@/views/dashboard/PageDashboard.vue"),
  },
  {
    path: "/financing",
    name: "Financing",
    meta: {
      icon: Icons.CASH4,
      title: "Financiamentos",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      actions: ["financingIntegration"],
      isBeta: true,
    },
    component: () => import("@/views/financing/PageFinancing.vue"),
    children: [
      {
        path: "/financing/details/:financingId",
        name: "FinancingDetails",
        meta: {
          hiddenMenu: true,
          icon: Icons.CASH4,
          title: "Financiamentos",
          local: ["navBar"],
          permissions: ["admin", "staff", "integrator"],
          actions: ["financingIntegration"],
        },
        component: () =>
          import(
            "@/components/plataform/financing/details/PageFinancingDetails.vue"
          ),
      },
    ],
  },
  // ##########################
  //       ACOMPANHAMENTO
  // ##########################
  // {
  //   path: "/viewedKits",
  //   name: "ViewedKits",
  //   meta: {
  //     icon: Icons.ARCHIVEEYE,
  //     title: "Kits Pesquisados",
  //     local: ["navBar"],
  //     permissions: ["admin", "staff"],
  //     staffPermissions: ["viewedKits"],
  //   },
  //   component: () => import("@/views/viewedKits/ViewedKits.vue"),
  // },
  {
    path: "/pageTaskPlan",
    name: "PageTaskPlan",
    meta: {
      icon: Icons.CALENDAR5,
      title: "Tarefas",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: [],
      actions: ["distributorCRM"],
      isBeta: true,
    },
    component: () => import("@/views/taskPlan/PageTaskPlan.vue"),
  },
  {
    path: "/budgets",
    name: "Budgets",
    meta: {
      icon: Icons.TEXTBOXSEARCH,
      title: "Orçamentos",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["budget"],
      staffPermissions: ["budget"],
    },
    component: () => import("@/views/budgets/PageBudgets.vue"),
  },
  {
    path: "/budgets/detail/:requestId",
    name: "BudgetsDetails",
    meta: {
      hiddenMenu: true,
      title: "Detalhes Orçamento",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      staffPermissions: ["budget.details"],
    },
    component: () =>
      import("@/components/plataform/commonRequestComponents/DetailsScreen"),
  },
  {
    path: "/requests",
    name: "Requests",
    meta: {
      type: "order",
      icon: Icons.TEXTBOXCHECK,
      title: "Pedidos",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      integratorPermissions: ["order"],
      staffPermissions: ["order"],
    },
    component: () => import("@/views/requests/PageRequests.vue"),
  },
  {
    path: "/requests/detail/:requestId",
    name: "RequestsDetails",
    meta: {
      hiddenMenu: true,
      title: "Detalhes Pedido",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator"],
      staffPermissions: ["order.details"],
    },
    component: () =>
      import("@/components/plataform/commonRequestComponents/DetailsScreen"),
  },
  {
    path: "/request/payment-details/:requestId",
    name: "RequestPaymentDetails",
    props: true,
    meta: {
      hiddenMenu: true,
      title: "Detalhes Pagamento Pedido",
      local: ["navBar"],
      permissions: ["admin", "staff", "integrator", "open"],
      staffPermissions: ["budget.details"],
    },
    component: () => import("@/views/requests/PageRequestPayment.vue"),
  },
  {
    path: "/integrators",
    name: "Integrators",
    meta: {
      icon: Icons.INTEGRATOR,
      title: "Empresas de Energia",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["integrators.read"],
      onePermissionNeeded: true,
      isBeta: true,
    },
    component: () => import("@/views/integrators/PageIntegrators.vue"),
    children: [
      {
        path: "/integrators/detail/:integratorId",
        name: "IntegratorDetails",
        meta: {
          title: "Detalhe integrador",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["integrators.details"],
        },
        component: () =>
          import("@/views/integrators/PageIntegratorDetails.vue"),
      },
      {
        path: "/integrators/portfolio/management",
        name: "IntegratorPortfolioManagement",
        meta: {
          title: "Gestão de carteira",
          local: ["navBar"],
          permissions: ["admin"],
          staffPermissions: ["extraItem", "importRequestFile"],
        },
        component: () =>
          import("@/views/integrators/PageIntegratorPortfolioManagement.vue"),
      },
    ],
  },
  {
    path: "/sellers",
    name: "Sellers",
    meta: {
      icon: Icons.ACCOUNTGROUP,
      title: "Colaboradores",
      local: ["navBar"],
      permissions: ["admin", "staff"],
      staffPermissions: ["staff"],
    },
    component: () => import("@/views/GroupRouter.vue"),
    children: [
      {
        path: "/pageSellers",
        name: "PageSellers",
        meta: {
          title: "Colaboradores",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["staff"],
        },
        component: () => import("@/views/sellers/PageSellers.vue"),
      },
      {
        path: "/pageSalesGoal",
        name: "PageSalesGoal",
        meta: {
          title: "Metas de Venda",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["staff"],
        },
        component: () => import("@/views/salesGoal/PageSalesGoal.vue"),
      },
      {
        path: "/sellers/detail/:sellerId",
        name: "SellerDetails",
        meta: {
          title: "Detalhe colaboradores",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["staff.details"],
        },
        component: () => import("@/views/sellers/PageSellerDetails.vue"),
      },
      {
        path: "/pageCommission",
        name: "PageCommission",
        meta: {
          title: "Comissão",
          local: ["navBar"],
          permissions: ["admin", "staff"],
          staffPermissions: ["staff"],
        },
        component: () => import("@/views/commission/PageCommission.vue"),
      },
    ],
    redirect: { name: "PageSellers" },
  },
];
