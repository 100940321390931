import BaseFilter from "./BaseFilter";
export default class ProductClassFilterModule extends BaseFilter {
  constructor(isInventory = false) {
    const filter = {
      minPowerWP: null,
      maxPowerWP: null,
      isToSellOptions: [],
      manufacturer: [],
      productCode: null,
      name: null,
      typeOfCell: null,
      model: null,
    };
    if (!isInventory) {
      Object.assign(filter, {
        minPrice: null,
        maxPrice: null,
      });
    }
    super(filter);
  }
}
