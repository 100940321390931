import { User } from "@/request";

const TTL = 15 * 1000;

const state = {
  groupType: null,
  distributorId: null,
  dealStatus: [],
  refusedDealStatusIds: [],
  lastModified: null,
  restrictOptions: {
    statusToBlockEdition: "finished",
    statusToDiscountStock: null,
  },
};

const mutations = {
  SET_DEAL_STATUS: (state, dealStatus) => {
    state.dealStatus = dealStatus;
    state.lastModified = Date.now();
  },
  SET_REFUSED_DEAL_STATUS: (state, dealStatusIds) => {
    state.refusedDealStatusIds = dealStatusIds;
  },
  SET_GROUP_TYPE: (state, type) => {
    state.groupType = type;
  },
  SET_DISTRIBUTOR_ID: (state, id) => {
    state.distributorId = id;
  },
  SET_STATUS_RESTRICT_OPTIONS: (state, data) => {
    state.restrictOptions.statusToBlockEdition =
      data?.statusToBlockEdition || "finished";
    state.restrictOptions.statusToDiscountStock = data?.statusToDiscountStock;
  },
  RESET(state) {
    state.groupType = null;
    state.distributorId = null;
    state.dealStatus = [];
    state.lastModified = null;
  },
};

const getters = {
  expired: (state) => Date.now() - state.lastModified > TTL,
  dealStatus: (state) => state.dealStatus,
  dealStatusToCheckRefused: (state) => state.refusedDealStatusIds,
  restrictOptions: (state) => state.restrictOptions,
  groupType: (state) => state.groupType,
  distributorId: (state) => state.distributorId,
  indexBlockedEditing: (state) =>
    state.dealStatus.findIndex((h) =>
      [h.value, h.legacyName].includes(
        state.restrictOptions.statusToBlockEdition
      )
    ),
  indexDealStatus: (state) => (status) =>
    state.dealStatus.findIndex((h) => [h.value, h.legacyName].includes(status)),
  isBlockedEditing: (state, getters) => (deal) => {
    const status =
      deal?.dealStatus?.id ||
      deal?.dealStatus?.meta?.legacyName ||
      deal?.status;
    if (!status) return false;
    return getters.indexDealStatus(status) >= getters.indexBlockedEditing;
  },
};

const actions = {
  async fetchDealStatus({ commit, state, getters, rootGetters }, payload) {
    let dealStatus = state.dealStatus;
    const dispatch = async () => {
      try {
        commit("SET_GROUP_TYPE", payload.type);
        commit("SET_DISTRIBUTOR_ID", payload.distributorId);

        const response = await User.readDealStatusTerminations({
          type: payload.type,
          parentId: payload.distributorId,
        });

        if (!response.error) {
          dealStatus = (response.data.items || [])
            .sort((a, b) => a.group?.meta?.order - b.group?.meta?.order)
            .map((item) => {
              return {
                name: item.name,
                value: item.id,
                tags: item.tags || [],
                description: rootGetters["userData/isIntegrator"]
                  ? item.group.name
                  : `${item.group.name} - ${item.name}`,
                category: item.group.id || null,
                color: item.meta?.color,
                legacyName: item.meta?.legacy,
                order:
                  Number((item.group.meta?.order || 0) * 1000) + item.order,
                group: item.group
                  ? {
                      id: item.group.id,
                      name: item.group.name,
                      parentId: item.group.parentId,
                      parentType: item.group.parentType,
                      color: item.group.meta?.color,
                      order: item.group.meta?.order || 0,
                      legacyName: item.group.meta?.legacyName,
                    }
                  : {},
              };
            });

          const dealStatusToCheckRefused = dealStatus.filter(
            (h) => h.group?.legacyName === "refused"
          );
          const dealStatusToCheckRefusedId = dealStatusToCheckRefused.map(
            (dealStatus) => dealStatus.value
          );

          commit("SET_REFUSED_DEAL_STATUS", dealStatusToCheckRefusedId);
          commit("SET_DEAL_STATUS", dealStatus);
        }
        return dealStatus;
      } catch (e) {
        commit("SET_DEAL_STATUS", dealStatus);
        return dealStatus;
      }
    };

    if (
      state.groupType === payload.type &&
      !getters.expired &&
      !payload?.force
    ) {
      return state.dealStatus;
    }

    return dispatch();
  },
  async fetchStatusRestrictOptions({ commit }) {
    const ret = await User.readStatusRestrictOptions();
    commit("SET_STATUS_RESTRICT_OPTIONS", ret.data || {});
  },
  reset({ commit }) {
    commit("RESET");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
