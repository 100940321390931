export class FormulaHelper {
  static parseRecommendationResult(res = 0) {
    if (res < 0) return 0;
    return Math.ceil(res);
  }

  static getSumFieldByReduce(array, fn, field, defaultValue) {
    return this.parseNumVar(
      array.reduce(
        (acc, cur) => acc + (fn(cur) ? Number(cur[field]) || 0 : 0),
        0
      ),
      defaultValue
    );
  }

  static parseStrVar(val, defaultValue = "") {
    return (val || defaultValue).toLowerCase();
  }

  static parseNumVar(val, defaultValue = Math.pow(10, -10)) {
    return val || defaultValue;
  }

  static getMainInverterType = (inverters) => {
    const tiposInversores = inverters
      .map((item) => item.tipo_inversor)
      .filter((v, i, a) => a.indexOf(v) === i);
    const tipoInversorPrincipal =
      ["string", "hibrido", "micro", "solaredge"].find((tipo) =>
        tiposInversores.includes(tipo)
      ) || "string";
    return tipoInversorPrincipal;
  };
}

export const RECOMMENDATION_SUPPORT = {
  /** Used to make comparisons with number return false */
  NOT_A_NUMBER: NaN,
};
