import Vue from "vue";
import Vuex from "vuex";
import theme from "./modules/theme";
import NotifyDialog from "./modules/NotifyDialog";
import loading from "./modules/loading";
import integratorPermissions from "./modules/integratorPermissions";
import requestEdit from "./modules/requestEdit";
import requestFilter from "./modules/tables/requestFilter";
import productFilter from "./modules/tables/productFilter";
import leadsIntegrator from "./modules/leadsIntegrator";
import products from "./modules/products";
import proposalSettingsIntegrator from "./modules/proposalSettingsIntegrator";
import businessIntegrator from "./modules/businessIntegrator";
import scheduleIntegrator from "./modules/scheduleIntegrator";
import tasksIntegrator from "./modules/tasksIntegrator";
import advertisingPopUps from "./modules/advertisingPopUps";
import companyPermissions from "./modules/companyPermissions";
import userData from "./modules/userData";
import storeFlow from "@/store/modules/storeFlow/storeFlow";
import tracking from "@/store/modules/tracking/tracking";
import integrator from "./modules/integrator";
import admin from "./modules/admin";
import requests from "./modules/requests";
import breadcrumb from "./modules/breadcrumb";
import filter from "./modules/filter";
import VuexPersistence from "vuex-persist";
import separatedSalesFilter from "./modules/separatedSalesFilter";
import dealStatus from "@/store/modules/dealStatus";
import tags from "@/store/modules/tags";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    NotifyDialog,
    loading,
    theme,
    integratorPermissions,
    requestEdit,
    requestFilter,
    productFilter,
    leadsIntegrator,
    products,
    proposalSettingsIntegrator,
    businessIntegrator,
    scheduleIntegrator,
    tasksIntegrator,
    advertisingPopUps,
    companyPermissions,
    userData,
    storeFlow,
    tracking,
    integrator,
    requests,
    breadcrumb,
    filter,
    separatedSalesFilter,
    dealStatus,
    tags,
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    new VuexPersistence({
      key: "PIED_STORAGE",
      storage: window.sessionStorage,
      modules: ["filter", "requests", "dealStatus"],
    }).plugin,
  ],
});
