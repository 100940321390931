import { User } from "@/request";
import {
  isSizingOrProposalCRMRoute,
  returnInitialPage,
  sortPurchaseItems,
} from "@/utils/utils";
import { Storage } from "@/localStorage";
import { INFO_SIZING, RESULT_SIZING } from "@/localStorage/storageKeys";
import router from "@/router";
import { DistributorFormulas } from "@/domain/formulas/Formulas";
import { KitEntity } from "@/domain/product/KitEntity";
import { ProductEntity } from "@/domain/product/ProductEntity";
import { RegionsEntity } from "@/domain/product/RegionsEntity";
import {
  PURCHASE_TYPES,
  PURCHASE_TYPE_CLOSED_KIT,
  PURCHASE_TYPE_CUSTOM_KIT,
} from "@/utils/constants";

const getDefaultState = () => {
  return {
    loaded: false,
    structure: null,
    sizingPower: 0,
    fromDimension: false,
    stepsMountKit: [],
    missingProducts: [],
    itemToShowDetails: null,
    selectedAutomaticModule: null,
    automaticModuleInput_kWp: null,
    pastKitPriceVariables: {},
    kitSettings: {
      showStringBox: true,
      showStructureLayout: true,
      multipleStructureLayout: false,
      showEditedRecommendation: false,
      modulesOrientation: "ambas",
      stepsMountKitOrientation: "horizontal",
      showButtonUnknownArrangement: true,
      canSelectMultipleModelOfModules: true,
    },
    automaticUserInputs: {
      typeLink: "",
      voltage: "",
      typeInverter: "",
      producer: [],
    },
    customPower: {
      minPower: 0,
      maxPower: 0,
    },
    mountKitSettings: {
      howIntegratorsCanMountKit: "manual",
      requiredInverterToMountKit: true,
      automaticRecommendationCurrent: "maximumPower",
      inversorPowerInputMinPercent: 80,
      enableInversorPowerInputMinPercentage: false,
    },
  };
};

const disponibilidadeFilter = (disponibilidade, prodDisp = []) => {
  if (prodDisp.length === 0) return true;
  return (
    disponibilidade.length === 0 ||
    disponibilidade.filter((element) => prodDisp.includes(element)).length > 0
  );
};

const state = {
  products: [],
  formulas: null,
  regionsEntity: null,
  kits: [],
  totalKits: -1,
  structuresToFilter: [],
  structureNames: [],
  ...getDefaultState(),
};

const mutations = {
  ["setLoaded"](state, value) {
    state.loaded = value;
  },
  ["setProductStateVars"](state, payload) {
    const allowedKeys = [
      "automaticModuleInput_kWp",
      "selectedAutomaticModule",
      "itemToShowDetails",
      "stepsMountKit",
      "mountKitSettings",
    ];
    for (let key of allowedKeys) {
      if (key in payload) state[key] = payload[key];
    }
  },
  ["setRegionsEntity"](state, payload) {
    const { regions = [] } = payload;
    state.regionsEntity = new RegionsEntity(regions);
  },
  ["setProductsCenters"](state, uf = "") {
    const centerOrder = state.regionsEntity?.getCenterOrderByState(uf);
    state.products.forEach((product) => {
      product.setAvailableCenter(centerOrder);
    });
    state.kits.forEach((kit) => {
      kit.setAvailableCenter(centerOrder);
    });
  },
  ["setProducts"](state, payload) {
    const { products = [] } = payload;
    state.products = products.map((item) => new ProductEntity(item));
  },
  ["setKits"](state, payload) {
    state.kits = payload.kits;
    state.totalKits = payload.totalKits;
    state.structuresToFilter = payload.structuresToFilter;
  },
  ["setKitSettings"](state, payload) {
    const allowedKeys = [
      "showStringBox",
      "showStructureLayout",
      "multipleStructureLayout",
      "showEditedRecommendation",
      "modulesOrientation",
      "showButtonUnknownArrangement",
      "showStringBox",
      "stepsMountKitOrientation",
      "canSelectMultipleModelOfModules",
    ];
    for (let key of allowedKeys) {
      if (key in payload) state.kitSettings[key] = payload[key];
    }
  },
  ["remove"](state, item) {
    item.amount = 0;
    if (item.automaticallyAdded) delete item.automaticallyAdded;
  },
  ["resetRecommendation"](state, item) {
    item.amount = item.recAmount;
  },
  ["setProductPrice"](state, { item, price }) {
    item.price = price;
  },
  ["setProductAmount"](
    state,
    {
      item,
      amount,
      recAmount,
      freeAmount,
      automaticallyAdded = null,
      recAutomaticAmount = null,
    }
  ) {
    item.amount = amount;
    item.recAutomaticAmount = recAutomaticAmount;
    if (typeof recAmount == "number") item.recAmount = recAmount;
    if (typeof freeAmount == "number") item.freeAmount = freeAmount;
    if (automaticallyAdded != null)
      item.automaticallyAdded = automaticallyAdded;
  },
  ["setProductCenter"](state, { item, center }) {
    item.center = typeof center == "string" ? center : center?._id;
  },
  ["setAutomaticUserInputs"](state, payload) {
    for (let key of ["typeLink", "voltage", "typeInverter", "producer"]) {
      if (key in payload) state.automaticUserInputs[key] = payload[key];
    }
  },
  ["pushProduct"](state, product) {
    state.products.push(new ProductEntity(product));
  },
  ["setStructureNames"](state, payload) {
    state.structureNames = payload.structureNames;
  },
  ["setSizingPower"](state, payload) {
    state.sizingPower = payload.sizingPower;
  },
  ["setStructure"](state, structure) {
    state.structure = structure;
  },
  ["setCustomPower"](state, payload) {
    const { minPower, maxPower } = payload;
    state.customPower.minPower = minPower;
    state.customPower.maxPower = maxPower;
  },
  ["setFromDimension"](state, payload = false) {
    state.fromDimension = payload;
  },

  ["addKits"](state, { item, amount = 1 }) {
    let chosenStructure = item.structure.find(
      (st) => st.id === item.structureId
    );

    if (chosenStructure) {
      chosenStructure.amount += amount;
      item.amount += amount;
    }
  },
  updateKitAmount(state, { kit, amount }) {
    let chosenStructure = kit.structure.find((st) => st.id === kit.structureId);

    if (chosenStructure) {
      chosenStructure.amount = amount;
      kit.amount = amount;
    }
  },
  ["removeKit"](state, item) {
    item.amount = 0;
    item.structure.forEach((st) => (st.amount = 0));
  },
  ["resetState"](state) {
    Object.assign(state, getDefaultState(), { products: [], kits: [] });
  },
  ["setMissingProducts"](state, payload) {
    state.missingProducts = Object.assign([], payload);
  },
  ["setPastKitPriceVariables"](state, payload) {
    state.pastKitPriceVariables = payload.pastKitPriceVariables;
  },
  ["loadFormulas"](state, payload) {
    state.formulas = payload;
  },
};

const actions = {
  async baseLoad(
    { dispatch, commit, getters },
    { purchaseType, eventType, ecommerce }
  ) {
    commit("setLoaded", false);
    commit("resetState");

    if (purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]) {
      commit("setKits", { kits: [], totalKits: 0, structuresToFilter: [] });
    }

    if (
      purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT] ||
      purchaseType === PURCHASE_TYPES[PURCHASE_TYPE_CUSTOM_KIT]
    ) {
      await dispatch("getInfoSizing");
    }

    const [, ret] = await Promise.all([
      dispatch("readFormulas"),
      await User.readProductsBaseLoad({ purchaseType, eventType, ecommerce }),
    ]);

    if (!ret.error && ret.data) {
      if (ret.data?.regions) {
        const { regions = [] } = ret.data;
        commit("setRegionsEntity", { regions });
      }

      if (ret.data?.products) {
        const { products = [] } = ret.data;
        commit("setProducts", { products });
      }

      if (ret.data?.kitSettings) {
        commit("setKitSettings", ret.data.kitSettings);
      }

      if (ret.data?.mountKitSettings) {
        const data = ret.data.mountKitSettings;
        let mountKitSettings = { ...getters.mountKitSettings };

        Object.assign(mountKitSettings, data.mountKitSettings);

        if (data.howIntegratorsCanMountKit) {
          Object.assign(mountKitSettings, {
            howIntegratorsCanMountKit: data.howIntegratorsCanMountKit,
          });
        }

        if (typeof data.requiredInverterToMountKit == "boolean") {
          Object.assign(mountKitSettings, {
            requiredInverterToMountKit: data.requiredInverterToMountKit,
          });
          commit("setProductStateVars", { mountKitSettings });
        }
      }

      if (ret.data?.structureNames) {
        commit("setStructureNames", {
          structureNames: ret.data.structureNames.sort((a, b) =>
            a.name.localeCompare(b.name)
          ),
        });
      }

      if (ret.data?.stepsMountKit) {
        const stepsMountKit = ret.data.stepsMountKit.filter(
          (step) =>
            step.show &&
            (step.type !== "string" || ret.data.kitSettings?.showStringBox)
        );

        stepsMountKit.forEach((str, index) => {
          str.index = index + 1;
          switch (str.type) {
            case "module":
              str.component = "MountKitStepModule";
              break;

            case "inverter":
              str.component = "MountKitStepInverter";
              break;

            case "string":
              str.component = "MountKitStepStringBox";
              break;

            case "structure":
              str.component = "MountKitStepStructure";
              break;

            case "product":
              str.component = "MountKitStepOtherProducts";
              break;

            case "checkout":
              str.component = "MountKitStepCheckout";
              break;
          }
        });

        if (!getters.customMountKitSteps) {
          stepsMountKit.forEach((i) => {
            if (i.type === "inverter")
              i.required = getters.requiredInverterToMountKit;
          });
        }

        commit("setProductStateVars", { stepsMountKit });
      }
    }

    if (eventType !== "budgetToOrder") {
      await dispatch("recalculatePrices");
    }

    commit("setLoaded", true);
  },

  async readFormulas({ commit, rootGetters }) {
    const ret = await User.readDistributorProcessedFormulas();

    if (!ret.data || ret.error) {
      returnInitialPage(rootGetters["storeFlow/type"]);
    } else {
      const formulas = new DistributorFormulas(ret.data);
      commit("loadFormulas", formulas);
    }
  },

  async recalculatePrices({ dispatch, commit, getters, rootGetters }) {
    if (!getters.formulasEntity) {
      await dispatch("readFormulas");
    }
    if (
      rootGetters["storeFlow/checkout/eventType"] === "budgetToOrder" ||
      !getters.formulasEntity
    ) {
      return false;
    }

    if (
      rootGetters["storeFlow/purchaseType"] ===
      PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]
    ) {
      return dispatch("recalculateClosedKitPrices");
    }

    try {
      let priceChange = false;
      const allProducts =
        getters.formulasEntity.calculateMultipleProductsPrices();

      for (const product of getters.allProducts) {
        const newPrice = allProducts.find(
          (p) => p.productCode === product.productCode
        )?.price;

        if (typeof newPrice === "number" && newPrice !== product.price) {
          commit("setProductPrice", { item: product, price: newPrice });
          priceChange = true;
        }
      }

      if (priceChange) {
        await dispatch("storeFlow/checkout/calculateValueInvoice", null, {
          root: true,
        });
      }
    } catch (e) {
      let selected = getters.allProducts.filter((item) => item.amount > 0);

      await User.errorReport(
        "Erro ao calcular preço",
        "price",
        {
          state: rootGetters["storeFlow/checkout/stateForCalculation"],
          products: selected.map((item) => item.productCode),
        },
        e
      );
    }
  },

  async recalculateClosedKitPrices({ dispatch, getters, rootGetters }) {
    if (!getters.formulasEntity) return;
    try {
      const priceChange =
        await getters.formulasEntity.calculateMultipleKitPrices();
      if (priceChange) {
        await dispatch("storeFlow/checkout/calculateValueInvoice", null, {
          root: true,
        });
      }
    } catch (e) {
      User.errorReport(
        "Erro ao calcular preço",
        "price",
        {
          state: rootGetters["storeFlow/checkout/stateForCalculation"],
          products: getters.kit?.map((item) => item.productCode),
        },
        e
      );
    }
  },

  setModuleQuantityAutomatically({ dispatch, commit, getters }) {
    getters.itemsSelectedModule
      .filter((i) => i.automaticallyAdded)
      .forEach((i) => commit("remove", i));
    let amount = Math.ceil(
      (getters.automaticModuleInput_kWp * 1000) /
        Number(getters.selectedAutomaticModule.potencia_WP)
    );

    dispatch("setProductAmount", {
      item: getters.selectedAutomaticModule,
      amount,
      recAmount: 0,
      freeAmount: 0,
      automaticallyAdded: true,
      recAutomaticAmount: amount,
    });
    dispatch("recalculatePrices");
  },

  setProductAmount(
    { dispatch, commit },
    {
      item,
      amount,
      recAmount,
      freeAmount,
      automaticallyAdded = null,
      recAutomaticAmount = null,
    }
  ) {
    commit("setProductAmount", {
      item,
      amount,
      recAmount,
      freeAmount,
      automaticallyAdded,
      recAutomaticAmount,
    });
    dispatch("recalculatePrices");
  },

  removeProduct({ dispatch, commit }, item) {
    commit("remove", item);
    dispatch("recalculatePrices");
  },

  async getInfoSizing({ commit }) {
    let dimension = await Storage.getItem(INFO_SIZING);
    let resultSizing = await Storage.getItem(RESULT_SIZING);
    let sizingPower = (
      resultSizing?.power ||
      dimension?.power ||
      0
    ).convertToNumber();
    if (
      !isSizingOrProposalCRMRoute() &&
      !router.history.current.params.preserveSizingResult
    ) {
      await Storage.removeItem(INFO_SIZING);
      await Storage.removeItem(RESULT_SIZING);
    }
    if (dimension?.state?.sigla) {
      commit(
        "storeFlow/checkout/setAdditionalInfoData",
        dimension.state.sigla,
        { root: true }
      );
    }
    if (resultSizing) {
      commit("setSizingPower", { sizingPower });
      commit("setFromDimension", true);
      commit("setProductStateVars", { automaticModuleInput_kWp: sizingPower });
    }
  },
  async readKits({ dispatch, commit, getters, rootGetters }, filterObj) {
    let error = false;

    if (typeof filterObj !== "object") {
      error = true;
    }

    const {
      minPower = undefined,
      maxPower = undefined,
      structure = "",
      page = 1,
      limit = 10,
    } = filterObj || {};
    const isNumber = (value) => !isNaN(value);

    const query = { page, limit };

    if (maxPower === 1000 && minPower === 0) {
      query.fullList = true;
    }

    if (isNumber(maxPower) || isNumber(minPower)) {
      commit("setCustomPower", {
        minPower: isNumber(minPower) ? minPower : 0,
        maxPower: isNumber(maxPower) ? maxPower : 1000,
      });
    } else {
      error = true;
    }

    query.minPower = getters.minPowerReadKits;
    query.maxPower = getters.maxPowerReadKits;
    query.structure = structure;

    const ret = error ? {} : await User.readKitsToBuy(query);

    commit("setFromDimension", false);
    if (!ret.data?.items?.length) {
      dispatch(
        "NotifyDialog/openNotifyWarning",
        {
          title: "AVISO",
          message: "Sem kits nessa faixa de potência",
        },
        { root: true }
      );

      if (!isSizingOrProposalCRMRoute()) {
        await Storage.removeItem(INFO_SIZING);
        await Storage.removeItem(RESULT_SIZING);
      }
      commit("setKits", { kits: [], totalKits: 0, structuresToFilter: [] });
    } else {
      const state = rootGetters["storeFlow/checkout/stateForCalculation"];
      const selectedIds = getters.kitsCheckout.map(
        (kit) => kit.id + kit.structureId
      );

      const chunkedKits = KitEntity.getChunkedKits(ret.data.items).filter(
        (kit) => !selectedIds.includes(kit.id + kit.structureId)
      );

      chunkedKits.push(...getters.kitsCheckout);
      commit("setKits", {
        kits: chunkedKits,
        totalKits: ret.data.totalItems,
        structuresToFilter: ret.data.filter?.structures,
      });
      commit("setProductsCenters", state);
      dispatch("recalculateClosedKitPrices");
    }
  },

  async addRecommendationsMain({ dispatch, commit, getters, rootGetters }) {
    try {
      for (let i = 0; i < 10; i++) {
        let noNewChanges = true;
        const recommendations =
          await getters.formulasEntity.calculateRecommendations();

        for (const recommendedItem of recommendations || []) {
          const product = getters.allProducts.find(
            (prod) => prod.productCode === recommendedItem.productCode
          );

          if (
            product &&
            ["recAmount", "recAutomaticAmount", "freeAmount"]
              .map((key) => {
                return key;
              })
              .map((key) => (product[key] || 0) !== (recommendedItem[key] || 0))
              .some(Boolean)
          ) {
            noNewChanges =
              noNewChanges && product.amount === recommendedItem.amount;
            commit("setProductAmount", {
              item: product,
              amount: recommendedItem.amount,
              recAutomaticAmount: product.recAutomaticAmount,
              recAmount: recommendedItem.recAmount,
              freeAmount: recommendedItem.freeAmount,
            });
          }
        }

        if (noNewChanges) {
          break;
        }
      }
    } catch (e) {
      User.errorReport(
        "Erro ao calcular recomendações",
        "recommendation",
        {
          state: rootGetters["storeFlow/checkout/stateForCalculation"],
          products: getters.itemsCheckout.map((item) => item.productCode),
          kitPotence: getters.totalModulesPower,
          qtyInversors: getters.totalInvertersAmount,
          qtyModules: getters.totalModulesAmount,
          qtyInverters: getters.triInverterAmount,
          qtyOthers: getters.totalComplementaryEquipmentAmount,
          qtyMonoInversors: getters.monoInverterAmount,
        },
        e
      );
    }
    dispatch("recalculatePrices");
  },
};

const getters = {
  isLoaded: (state) => state.loaded,
  allProducts: (state, getters) =>
    state.products.filter((p) =>
      disponibilidadeFilter(
        getters.disponibilidade,
        p.disponibilidade_condicional
      )
    ),
  itemsModule: (state, getters) =>
    getters.allProducts.filter((p) => p.type === "module"),
  itemsInverter: (state, getters) =>
    getters.allProducts.filter((p) => p.type === "inverter"),
  itemsStringBox: (state, getters) =>
    getters.allProducts.filter((p) => p.type === "stringBox"),
  itemsComplementaryEquipment: (state, getters) =>
    getters.allProducts.filter((p) => p.type === "complementaryEquipment"),
  itemsProducts: (state, getters) =>
    getters.itemsComplementaryEquipment.filter(
      (item) => !item.category.type || item.category.type === "others"
    ),
  itemsStructures: (state, getters) =>
    getters.itemsComplementaryEquipment.filter(
      (item) => item.category.type && item.category.type === "structure"
    ),
  itemsSelected: (state) =>
    state.products
      .filter((a) => a.amount > 0 || a.amount === null || a.amount === "")
      .sort(sortPurchaseItems),
  kitsSelected: (state) =>
    state.kits
      .filter((a) => a.amount > 0 || a.amount === null || a.amount === "")
      .sort(sortPurchaseItems),
  itemsSelectedModule: (state, getters) =>
    getters.itemsSelected.filter((item) => item.type === "module"),
  itemsSelectedInverter: (state, getters) =>
    getters.itemsSelected.filter((item) => item.type === "inverter"),
  itemsSelectedStringBox: (state, getters) =>
    getters.itemsSelected.filter((item) => item.type === "stringBox"),
  itemsSelectedProducts: (state, getters) =>
    getters.itemsProducts.filter(
      (item) => item.amount > 0 || item.amount === null || item.amount === ""
    ),
  itemsSelectedStructures: (state, getters) =>
    getters.itemsStructures.filter(
      (item) => item.amount > 0 || item.amount === null || item.amount === ""
    ),
  itemsCheckout: (state, getters) =>
    getters.itemsSelected.filter((i) => i.amount > 0).sort(sortPurchaseItems),
  kitsCheckout: (state) => state.kits.filter((i) => i.amount > 0),
  kits: (state) => state.kits,
  disponibilidade: (state, getters) =>
    getters.itemsSelected
      .filter((item) => item.amount > 0)
      .reduce((a, b) => {
        let disponibilidade_b = b.disponibilidade_condicional || [];
        if (a.length > 0 && disponibilidade_b.length > 0)
          return a.filter((element) => disponibilidade_b.includes(element));
        else if (a.length > 0 && disponibilidade_b.length === 0) return a;
        else if (a.length === 0) return disponibilidade_b;
      }, []),
  powerModules: (state, getters) =>
    getters.itemsModule.reduce(
      (acc, cur) => acc + Number(cur.potencia_WP) * Number(cur.amount || 0),
      0
    ),
  totalInverterInputPower_W: (state, getters) =>
    getters.itemsSelectedInverter
      .map((item) => item.inverterInputPower * 1000)
      .reduce((acc, cur) => acc + cur, 0),
  totalInverterOutputPower_W: (state, getters) =>
    getters.itemsSelectedInverter
      .map((item) => item.inverterOutputPower * 1000)
      .reduce((acc, cur) => acc + cur, 0),
  stepsMountKitOrientation: (state) =>
    state.kitSettings.stepsMountKitOrientation,

  triInverterAmount: (state, getters) =>
    getters.itemsSelectedInverter
      .filter((i) => i.tipo_ligacao === "trifasico")
      .reduce((acc, cur) => acc + Number(cur.amount || 0), 0),
  monoInverterAmount: (state, getters) =>
    getters.itemsSelectedInverter
      .filter((i) => i.tipo_ligacao === "monofasico")
      .reduce((acc, cur) => acc + Number(cur.amount || 0), 0),
  formulasEntity: (state) => state.formulas,
  showStringBox: (state) => state.kitSettings.showStringBox,
  showEditedRecommendation: (state) =>
    state.kitSettings.showEditedRecommendation,
  kitSettings: (state) => state.kitSettings,
  canSelectMultipleModelOfModules: (state) =>
    state.kitSettings.canSelectMultipleModelOfModules,
  baseProducts: (state, getters, rootState, rootGetters) =>
    rootGetters["storeFlow/purchaseType"] !==
    PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]
      ? getters.itemsCheckout || []
      : (getters.kits || []).reduce((acc, cur) => {
          acc.push(
            ...cur.products.map((p) => {
              let copy = { ...p };
              copy.amount = Number(cur.amount || 0) * Number(p.amount || 0);
              return copy;
            })
          );
          return acc;
        }, []),
  totalModulesAmount: (state, getters) =>
    getters.baseProducts
      .filter((p) => p.type === "module")
      .reduce((acc, cur) => acc + (Number(cur.amount) || 0), 0),
  totalModulesPower: (state, getters) =>
    getters.baseProducts
      .filter((p) => p.type === "module")
      .reduce(
        (acc, cur) =>
          acc + (Number(cur.potencia_WP) * Number(cur.amount) || 0) / 1000,
        0
      ),
  totalInvertersAmount: (state, getters) =>
    getters.baseProducts
      .filter((p) => p.type === "inverter")
      .reduce((acc, cur) => acc + (Number(cur.amount) || 0), 0),
  totalStringBoxAmount: (state, getters) =>
    getters.baseProducts
      .filter((p) => p.type === "stringBox")
      .reduce((acc, cur) => acc + (Number(cur.amount) || 0), 0),
  totalComplementaryEquipmentAmount: (state, getters) =>
    getters.baseProducts
      .filter((p) => p.type === "complementaryEquipment")
      .reduce((acc, cur) => acc + (Number(cur.amount) || 0), 0),
  totalProductsPrice: (state, getters, rootState, rootGetters) => {
    const value =
      rootGetters["storeFlow/purchaseType"] !==
      PURCHASE_TYPES[PURCHASE_TYPE_CLOSED_KIT]
        ? getters.itemsCheckout.reduce((acc, cur) => {
            let itemPricedAmount =
              Number(cur.amount || 0) - Number(cur.freeAmount || 0);
            return (
              acc +
              (itemPricedAmount > 0 ? itemPricedAmount : 0) *
                (Number(cur.price) || 0)
            );
          }, 0)
        : getters.kitsCheckout.reduce((acc, cur) => {
            let structurePrice = cur.structure?.reduce(
              (prev, str) => prev + str.amount * str.price,
              0
            );
            return (
              acc +
              (Number(cur.price) || 0) * (Number(cur.amount) || 0) +
              structurePrice
            );
          }, 0);
    return value;
  },
  structureNames: (state) =>
    state.structureNames
      .filter((item) => item.active === true)
      .sort((a, b) => a.createdAt > b.createdAt),
  sizingPower: (state) => state.sizingPower,
  powerToReadKits: (state, getters) =>
    (getters.minPowerReadKits + getters.maxPowerReadKits) / 2,
  minPowerReadKits: (state) => {
    return state.customPower.minPower;
  },
  maxPowerReadKits: (state) => {
    return state.customPower.maxPower;
  },
  totalKits: (state) => {
    return state.totalKits;
  },
  structuresToFilter: (state) => {
    return state.structuresToFilter;
  },
  fromDimension: (state) => state.fromDimension,
  structure: (state) => state.structure,
  automaticModuleInput_kWp: (state) => state.automaticModuleInput_kWp,
  selectedAutomaticModule: (state) => state.selectedAutomaticModule,
  selectedAutomaticModuleId: (state) => state.selectedAutomaticModule?.id,
  itemToShowDetails: (state) => state.itemToShowDetails,
  automaticUserInputs: (state) => state.automaticUserInputs,
  automaticInverterCheck: (state) =>
    state.automaticUserInputs?.typeLink && state.automaticUserInputs?.voltage,
  stepsMountKit: (state) => state.stepsMountKit,
  mountKitSettings: (state) => state.mountKitSettings,
  howIntegratorsCanMountKit: (state) =>
    state.mountKitSettings.howIntegratorsCanMountKit,
  requiredInverterToMountKit: (state) =>
    state.mountKitSettings.requiredInverterToMountKit,
  inversorPowerInputMinPercent: (state) =>
    state.mountKitSettings.inversorPowerInputMinPercent,
  enableInversorPowerInputMinPercentage: (state) =>
    state.mountKitSettings.enableInversorPowerInputMinPercentage,
  customMountKitSteps: (state, getters, rootState) =>
    rootState.userData?.actions?.customMountKitSteps,
  missingProductsMessage: (state) => {
    if (state.missingProducts.length)
      return `${
        state.missingProducts.length > 1
          ? "Os equipamentos a seguir não estão mais disponíveis"
          : "O equipamento a seguir não está mais disponível"
      }:\n
        ${state.missingProducts
          .map((el, idx, arr) =>
            idx === arr.length - 1 ? `- ${el}` : `- ${el}\n`
          )
          .join(" ")}`;
    return "";
  },
  deletedRecommendedProducts: (state) =>
    state.products.filter(
      (a) => (a.recAmount > 0 || a.recAutomaticAmount > 0) && !(a.amount > 0)
    ),
  pastKitPriceVariables: (state) => state.pastKitPriceVariables,
  regionsEntity: (state) => state.regionsEntity,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
