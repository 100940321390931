export const taskPlanPriorityObj = {
  high: {
    text: "Alta",
    color: "#D80027",
    value: 1,
  },
  medium: {
    text: "Média",
    color: "#E0B400",
    value: 2,
  },
  low: {
    text: "Baixa",
    color: "#198F3A",
    value: 3,
  },
};

export const taskPlanPriorityObjectOptions = Object.keys(
  taskPlanPriorityObj
).map((key) => ({
  text: taskPlanPriorityObj[key].text,
  value: taskPlanPriorityObj[key].value,
  color: taskPlanPriorityObj[key].color,
}));

export const taskPlanPriorityTranslatorMapper = Object.keys(
  taskPlanPriorityObj
).reduce(
  (acc, cur) => ({
    ...acc,
    [taskPlanPriorityObj[cur].value]: taskPlanPriorityObj[cur].text,
  }),
  {}
);

export const taskPlanPriorityColorMapper = Object.keys(
  taskPlanPriorityObj
).reduce(
  (acc, cur) => ({
    ...acc,
    [taskPlanPriorityObj[cur].value]: taskPlanPriorityObj[cur].color,
  }),
  {}
);

export const taskPlanParentTypeObj = {
  none: "Nenhuma",
  budget: "Um orçamento",
  order: "Um pedido",
  company: "Uma empresa",
};

export const taskPlanParentTypeOptions = Object.keys(taskPlanParentTypeObj).map(
  (key) => ({
    text: taskPlanParentTypeObj[key],
    value: key,
  })
);

export const taskPlanParentTypes = Object.keys(taskPlanParentTypeObj).reduce(
  (acc, cur) => ({ ...acc, [cur.toUpperCase()]: cur }),
  {}
);

export const taskPlanParentTypeArray = Object.keys(taskPlanParentTypeObj);

export const taskPlanRecurrenceRepetitionPeriodTranslateObj = {
  day: "dia",
  week: "semana",
  month: "mês",
  quarter: "trimestre",
  semester: "semestre",
  year: "ano",
};

export const taskPlanRecurrenceRepetitionPeriodOptions = Object.entries(
  taskPlanRecurrenceRepetitionPeriodTranslateObj
).map(([key, value]) => ({
  text: value,
  value: key,
}));

export const taskPlanReportTypesObj = {
  TASKS_COMPLETE: "tasksComplete",
  SEGMENTED_BY_COMPANIES: "segmentedByCompanies",
};

export const taskPlanReportTranslateObj = {
  [taskPlanReportTypesObj.TASKS_COMPLETE]: "Tarefas completo",
  [taskPlanReportTypesObj.SEGMENTED_BY_COMPANIES]: "Segmentado",
};
