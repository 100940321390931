<template>
  <v-dialog
    v-model="show"
    transition="fade-transition"
    overlay-color="white"
    persistent
    scrollable
    :width="width"
    :max-width="maxWidth"
  >
    <v-card :loading="loading" :disabled="disabled">
      <v-app-bar
        :class="titleClass"
        :style="{ color: titleColor, fontSize: titleFontSize }"
        :height="appBarHeight"
        color="white"
        flat
        v-if="!hideHeader"
      >
        <div v-if="!cleanHead" class="d-flex flex-column justify-center">
          <span class="pied-dialog__title" v-html="title" />
          <span
            v-if="subtitle"
            v-html="subtitle"
            class="pied-dialog__subtitle"
          />
        </div>

        <v-spacer />
        <v-btn small icon class="pied-dialog__close-btn" @click="onClose">
          <v-icon>{{ $icons.CLOSE }}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-divider v-if="!subtitle && !cleanHead" class="pt-2" />
      <v-card-text
        :dense="dense"
        class="pied-dialog__body px-4"
        :data-padding="noPadding"
        :data-clean-head="cleanHead"
      >
        <slot name="body" />
      </v-card-text>

      <slot name="footer" />

      <template v-if="hasActions">
        <v-card-actions class="pied-dialog__actions">
          <slot name="actions" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from "@/components/mixins/DialogMixin";

export default {
  mixins: [DialogMixin],
  props: {
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "#424259",
    },
    titleFontSize: {
      type: String,
      default: "18px",
    },
    width: {
      type: [String, Number],
      default: "auto",
    },
    maxWidth: {
      type: [String, Number],
      default: "none",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    cleanHead: {
      type: Boolean,
      default: false,
    },
    titleAlign: {
      type: String,
      default: "center",
    },
    subtitle: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    customAppBarHeight: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasActions() {
      return this.$slots.actions;
    },
    titleClass() {
      return {
        "py-2": this.subtitle,
        "my-2": this.subtitle,
        [`justify-${this.titleAlign}`]: true,
        "has-subtitle": this.subtitle,
        "pied-dialog": true,
      };
    },
    appBarHeight() {
      if (this.customAppBarHeight) return this.customAppBarHeight;
      return this.subtitle ? "64px" : "48px";
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  border-radius: 12px;
}

.pied-dialog__title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  word-break: break-word;
  margin-right: 10px;
  font-size: 18px;
}

.pied-dialog__subtitle {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.pied-dialog__body {
  color: #2f353999 !important;
  text-align: center;
  padding-bottom: 28px !important;
  padding-top: 20px !important;

  &[no-padding] {
    padding: 0 !important;
  }

  &[dense] {
    padding: 12px !important;
  }

  &[data-clean-head="true"] {
    padding-top: 0 !important;
  }

  @media (max-width: 800px) {
    padding: 12px !important;
  }
}

.pied-dialog.has-subtitle {
  .pied-dialog__close-btn {
    top: -14px;
  }
}

.pied-dialog__close-btn {
  color: #424259 !important;
  position: relative;
  right: 8px;
  top: 0;
}

.pied-dialog__actions {
  justify-content: center;
  padding: 16px !important;
  gap: 16px;

  ::v-deep > div.double-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      width: 48% !important;
      height: 38px !important;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      height: 100px;
      > button {
        width: 100% !important;

        &:last-child {
          margin-top: 8px;
        }
      }
    }
  }

  ::v-deep button {
    width: 195px !important;
    height: 40px !important;
    margin-left: 0 !important;

    .v-btn__content {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      display: flex;
      flex: 1 !important;
    }
  }
}
</style>
