import { normalizeString } from "@/utils/StringUtils";
import Staff from "@/request/routes/Staff";
import store from "@/store";

export const TagItemParentTypeEnum = {
  COMPANY: "company",
  ORDER: "order",
  BUDGET: "budget",
};

export class TagItem {
  /**
   * ID tagGroupId
   * @type {string}
   */
  id = "";
  name = "";
  color = "";
  order = -1;
  distributor = "";
  parentType = TagItemParentTypeEnum.COMPANY;
  /**
   * ID related of tags
   *
   * @type {string}
   */
  tagItemId = "";
  /**
   * @type {boolean}
   * @private
   */
  _editing = false;
  /**
   * @type {TagItem|null}
   * @private
   */
  _original = null;
  /**
   * @type {boolean}
   * @private
   */
  _hover = false;

  get displayActions() {
    return this._hover || this._editing;
  }

  isEditing() {
    return this._editing;
  }

  openEdit() {
    this._editing = true;
    this._original = Object.assign({}, this);
  }

  cancelEdit() {
    this.color = this._original.color;
    this.name = this._original.name;
    this.closeEdit();
  }

  closeEdit() {
    this._editing = false;
    this._original = null;
  }

  showActions() {
    this._hover = true;
  }

  hideActions() {
    this._hover = false;
  }

  async save() {
    try {
      const result = await (!this.id
        ? Staff.createTagGroup(TagItemFactory.createTagGroup(this))
        : Staff.updateTagGroup(TagItemFactory.updateTagGroup(this)));

      if (result.error) {
        return new Promise.reject(result.error);
      }

      Object.assign(this, {
        id: result.data.id,
        order: result.data.order,
        createdAt: result.data.createdAt,
        updatedAt: result.data.createdAt,
      });
      this.closeEdit();
    } finally {
      //
    }
  }
}

export class TagItemBuilder {
  tag = new TagItem();

  setId(value) {
    this.tag.id = value;
    return this;
  }

  setName(value) {
    this.tag.name = value;
    return this;
  }

  setColor(value) {
    this.tag.color = value;
    return this;
  }

  setParentType(value) {
    this.tag.parentType = value;
    return this;
  }

  setOrder(value) {
    this.tag.order = value;
    return this;
  }

  setDistributor(value) {
    this.tag.distributor = value;
    return this;
  }

  setTagItemId(value) {
    this.tag.tagItemId = value;
    return this;
  }

  toForm() {
    return {
      id: this.tag.id,
      name: this.tag.name,
      color: this.tag.color,
      parentType: this.tag.parentType,
      order: this.tag.order,
      distributor: this.tag.distributor,
    };
  }

  toObject() {
    return this.tag;
  }
}

export class TagItemFactory {
  static createTagGroup(data) {
    return new TagItemBuilder()
      .setName(data?.name)
      .setColor(data?.color)
      .setParentType(data?.parentType)
      .toForm();
  }

  static updateTagGroup(data) {
    return new TagItemBuilder()
      .setId(data?.id)
      .setName(data?.name)
      .setColor(data?.color)
      .setParentType(data?.parentType)
      .setOrder(data?.order)
      .toForm();
  }

  static make(data) {
    return new TagItemBuilder()
      .setId(data?.id)
      .setName(data?.name)
      .setColor(data?.color)
      .setParentType(data?.parentType)
      .setOrder(data?.order)
      .setDistributor(data?.distributor)
      .setTagItemId(data?.tagItemId)
      .toObject();
  }
}

export class TagListItem {
  /**
   * @type {TagItem[]}
   */
  items = [];
  /**
   * @type {TagItem[]}
   */
  itemsCopy = [];
  /**
   * @type {TagItem[]}
   */
  selected = [];
  /**
   * @type {string}
   */
  search = "";
  /**
   * @type {string}
   */
  parentType = TagItemParentTypeEnum.COMPANY;
  /**
   * @type {string}
   */
  parentId = "";

  /**
   * @param parentType {string}
   * @param parentId {string}
   * @param items {TagItem[]}
   * @param selected {TagItem[]}
   */
  constructor(parentType, parentId, items = [], selected = []) {
    this.parentType = parentType;
    this.parentId = parentId;
    this.items = items;
    this.selected = selected;
  }

  /**
   * @param items {TagItem[]}
   * @returns {TagListItem}
   */
  setItems(items = []) {
    this.items = items;
    this.itemsCopy = items;
    return this;
  }

  /**
   * @param items {TagItem[]}
   * @returns {TagListItem}
   */
  setSelected(items = []) {
    this.selected = items;
    this.updateItems();
    return this;
  }

  /**
   * @param value {string}
   */
  async addItem(value) {
    const newObj = new TagItemBuilder()
      .setName(value)
      .setColor("#5B677B")
      .setParentType(this.parentType)
      .toObject();

    try {
      await newObj.save();
      this.itemsCopy.unshift(newObj);
    } catch (e) {
      console.warn("addItem.error", e);
    } finally {
      this.search = "";
      this.updateItems();
    }
  }

  /**
   * @param tag {TagItem}
   */
  async removeItem(tag) {
    const index = this.itemsCopy.indexOf(tag);
    if (index === -1) return;

    try {
      const result = await Staff.deleteTagGroup(tag);
      if (result.error) return;
      this.itemsCopy.splice(index, 1);
      this.updateItems();
      store.dispatch("tags/removeGroup", tag);
    } catch (e) {
      //
    }
  }

  /**
   * @param tag {TagItem}
   * @param force {boolean}
   */
  async removeItemSelected(tag, force = true) {
    const index = this.selected.findIndex((t) => t.id === tag.id);
    if (index === -1) return;

    let drop = true;

    if (force) {
      const result = await Staff.removeTagFromParent(tag, this.parentId);
      drop = !result.error;
    }

    if (drop) {
      this.selected.splice(index, 1);
      this.updateItems();
    }
  }

  /**
   * Seleciona item e atualiza lista de items.
   *
   * @param tag {TagItem}
   */
  async select(tag) {
    try {
      const result = await Staff.addTagToParent({
        distributor: tag.distributor,
        parentType: this.parentType,
        parentId: this.parentId,
        tagGroupId: tag.id,
      });

      if (result.error) {
        return;
      }
      tag.tagItemId = result.data.id;
      this.selected.push(tag);
      this.updateItems();
    } catch (e) {
      //
    }
  }

  /**
   * @param items {TagItem[]|null}
   */
  updateItems(items = null) {
    let values = items || this.itemsCopy;
    this.items = [...values].filter(
      (item) => !this.selected.some((h) => h.id === item.id)
    );
  }

  /**
   * @param search
   * @returns {boolean}
   */
  applyFilter(search = "") {
    this.search = search;

    if (!this.search.length) {
      this.updateItems();
      return false;
    }

    const filtered = [...this.itemsCopy].filter((h) =>
      normalizeString(h.name).includes(normalizeString(search))
    );

    this.updateItems([...filtered]);

    return filtered.length > 0;
  }
}
