import BaseFilter from "./BaseFilter";

export default class ProductClassFilterInverter extends BaseFilter {
  constructor(isInventory = false) {
    const filter = {
      minOutPower: null,
      maxOutPower: null,
      isToSellOptions: [],
      manufacturer: [],
      inverter: [],
      connection: [],
      voltage: null,
      productCode: null,
      name: null,
      model: null,
    };
    if (!isInventory) {
      Object.assign(filter, {
        minPrice: null,
        maxPrice: null,
      });
    }
    super(filter);
  }
}
