<template>
  <div class="pied-chip">
    <v-chip
      outlined
      :style="localStyle"
      :small="small"
      :x-small="xSmall"
      :dense="dense"
      :rounded="rounded"
      :close="clearable"
      @click:close="$emit('close')"
      @click="$emit('click')"
      v-bind="attrs"
      pill
      v-if="!tooltip"
    >
      <slot>
        <span class="text-truncate">{{ label }}</span>
      </slot>
    </v-chip>

    <v-tooltip bottom max-width="300px" v-else>
      <template #activator="{ on, attrs: tooltipAttrs }">
        <v-chip
          outlined
          v-on="on"
          :style="localStyle"
          :small="small"
          :x-small="xSmall"
          :dense="dense"
          :rounded="rounded"
          :close="clearable"
          @click:close="$emit('close')"
          @click="$emit('click')"
          v-bind="{ ...attrs, ...tooltipAttrs }"
          pill
        >
          <slot>
            <span class="text-truncate">{{ label }}</span>
          </slot>
        </v-chip>
      </template>
      <slot name="tooltip" :label="label" />
    </v-tooltip>
  </div>
</template>

<script>
import { hexToRgba } from "@/utils/DomUtils";

export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    color: {
      type: String,
      default: "button",
    },
    attrs: {
      type: Object,
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localStyle() {
      const isRGBA = /#.{6,8}/.test(this.color);
      const color = isRGBA
        ? hexToRgba(this.color, 0.3)
        : `var(--v-${this.color}-base)`;

      return {
        color: `${
          isRGBA
            ? `rgba(${hexToRgba(this.color, 1)})`
            : `var(--v-${this.color}-base)`
        }`,
        "border-color": `${isRGBA ? `rgba(${color})` : `${color}`}`,
        "background-color": `${
          isRGBA
            ? `rgba(${color})`
            : `color-mix(in srgb,${color},transparent 90%)`
        } !important`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.v-chip {
  height: 20px;
  font-size: 10px;
  font-weight: 700;
}

.v-chip ::v-deep {
  &[dense] {
    padding: 0 6px;
    border-radius: 4px;
  }

  &[rounded] {
    border-radius: var(--pied-border-base);
  }
}
</style>
